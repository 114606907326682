<template>
  <div>
    <div class="anomaly_statistics">
      <!-- 选择器 -->
      <div class="select_box">
        <!-- 运营商 -->
        <el-select
          v-supAdmin
          class="ipt_box mr12 mt12"
          v-model="id_operator"
          placeholder="请选择运营商"
          @change="selectOperator"
        >
          <el-option
            v-for="ite in operatorList"
            :key="ite.id"
            :label="ite.name_operator"
            :value="ite.id_operator"
          ></el-option>
        </el-select>

        <!-- 项目 -->
        <el-select
          v-model="id_project"
          placeholder="请选择项目"
          @change="selectProject"
          class="ipt_box mt12 mr12"
        >
          <el-option
            v-for="ite in filterProjectList"
            :key="ite.id"
            :label="ite.name_project"
            :value="ite.id"
          ></el-option>
        </el-select>

        <!-- 系统 -->
        <el-select
          class="ipt_box mr12 mt12"
          v-model="id_vir"
          placeholder="请选择系统"
          :disabled="id_project === ''"
        >
          <el-option
            v-for="(ite, idx) in systemList"
            :key="idx"
            :label="ite.name"
            :value="ite.id"
          ></el-option>
        </el-select>

        <!-- 异常类型 -->
        <el-select
          class="ipt_box mr12 mt12"
          v-model="device_type"
          placeholder="请选择预警类型"
        >
          <el-option
            v-for="ite in deviceTypeList"
            :key="ite.id"
            :label="ite.name"
            :value="ite.id"
          ></el-option>
        </el-select>

        <div class="mr12 mt12">
          <el-button type="primary" size="small" @click="search">查询</el-button>
          <el-button size="small" type="primary" plain @click="reset">重置</el-button>
          <el-button size="small" type="success" plain @click="exportExcel"
            >导出</el-button
          >
        </div>
      </div>

      <!-- 表格数据 -->
      <div class="table_box">
        <el-table
          :data="errorList"
          style="width: 100%"
          border
          :header-cell-style="{
            background: '#fafafb',
            height: '40px',
          }"
          :stripe="true"
          size="small"
        >
          <el-table-column label="系统名称" prop="name"></el-table-column>
          <el-table-column prop="warn_name" label="规则名称"></el-table-column>
          <el-table-column
            prop="warn_content"
            label="触发预警"
            min-width="140"
          ></el-table-column>

          <el-table-column label="持续时间(min)" prop="duration">
            <template #default="{ row }">
              <span>{{ getDuration(row) }}</span>
            </template>
          </el-table-column>

          <el-table-column label="预警类型" min-width="100">
            <template v-slot="{ row }">
              <span>{{ row.device_type | deviceTypeFmt }}</span>
            </template>
          </el-table-column>

          <el-table-column label="接收人" prop="account_recive">
            <template v-slot="{ row }">
              <span>{{ row.account_recive || "--" }}</span>
            </template>
          </el-table-column>

          <el-table-column label="处理人" prop="account_handle">
            <template v-slot="{ row }">
              <span>{{ row.account_handle || "--" }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="name_project" label="项目名称"></el-table-column>

          <el-table-column prop="time_warn" label="预警时间"></el-table-column>
        </el-table>
      </div>

      <!--分页-->
      <div class="pagination_box">
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="page_id + 1"
          :page-sizes="[20, 50, 100, 200]"
          :page-size="page_num"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <ExportExcel ref="exportExcelRef" />
  </div>
</template>

<script>
import { GetProjectList, GetErrorList, GetOperatorList2 } from "@/api";
import ExportExcel from "@/components/export_excel/export_excel.vue";

export default {
  name: "anomaly_statistics",
  components: { ExportExcel },
  data() {
    return {
      // 角色权限
      role: "",
      // 运营商列表
      operatorList: [],
      // 当前选中的运营商
      id_operator: "",
      // 选中的项目
      id_project: "",
      // 系统id
      id_vir: "",
      // 异常类型
      device_type: "",
      // 类型列表 0:水箱 1:水泵 2:机组
      deviceTypeList: [
        { id: 0, name: "水箱" },
        { id: 1, name: "水泵" },
        { id: 2, name: "机组" },
      ],
      page_id: 0,
      page_num: 20,
      total: 0,
      // 异常列表
      errorList: [],
      // 项目列表
      projectList: [],
      // 当前项目下的系统列表
      systemList: [],
    };
  },
  methods: {
    // 超级管理员进来获取运营商列表
    async getOperatorList() {
      const { ret, data, msg } = await GetOperatorList2();
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.operatorList = data.data;
    },

    // 选中运营商
    selectOperator() {
      this.id_project = "";
    },

    // 页面显示多少条数据
    sizeChange(s) {
      this.page_num = s;
      this.page_id = 0;
      this.getErrList();
    },

    // 页码变化
    currentChange(p) {
      this.page_id = p - 1;
      this.getErrList();
    },

    // 查询
    search() {
      this.page_id = 0;
      this.getErrList();
    },

    // 重置
    reset() {
      if (this.role === 100) {
        this.id_operator = "";
      }
      this.id_project = "";
      this.id_vir = "";
      this.device_type = "";
      this.page_id = 0;
      this.getErrList();
    },

    // 获取项目列表
    async getProjectList() {
      const { ret, data, msg } = await GetProjectList();
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.projectList = data.data;
    },

    // 选择了项目
    selectProject(id) {
      this.id_vir = "";
      const index = this.projectList.findIndex((i) => i.id === id);
      this.systemList = this.projectList[index].device_list;
    },

    // 获取参数列表
    getParams() {
      const params = {
        page_id: this.page_id,
        page_num: this.page_num,
        warn_status: 0,
      };
      if (this.role === 100 && this.id_operator) {
        params.id_operator = this.id_operator;
      }
      if (this.id_project) {
        params.id_project = this.id_project;
      }
      if (this.id_vir) {
        params.id_vir = this.id_vir;
      }
      if (this.device_type !== "") {
        params.device_type = this.device_type;
      }
      return params;
    },

    // 获取异常列表
    async getErrList() {
      const params = this.getParams();
      const { ret, data, msg } = await GetErrorList(params);
      if (ret !== 0) {
        return this.$message.error(msg);
      }
      this.total = data.data_cnt;
      this.errorList = data.data_list;
    },

    // 导出excel
    exportExcel() {
      const params = this.getParams();
      this.$refs.exportExcelRef.export({
        fn: GetErrorList,
        params,
        name: "异常统计",
        keyMap: {
          warn_name: "规则名称",
          warn_content: "触发预警",
          duration: "持续时间(min)",
          device_type: "预警类型",
          name: "系统名称",
          account_recive: "接收人",
          account_handle: "处理人",
          name_project: "项目名称",
          time_warn: "预警时间",
        },
        dataMap: ["data_list"],
        totalMap: ["data_cnt"],
        valueFmt: {
          device_type(v) {
            switch (v) {
              case 0:
                return "水箱";
              case 1:
                return "水泵";
              case 2:
                return "机组";
            }
          },
        },
      });
    },

    // 计算持续时间
    getDuration(row) {
      const s = row.time_warn.replace(/-/g, "/");
      const ts = new Date().getTime() - new Date(s).getTime();
      return parseInt(ts / 1000 / 60);
    },
  },
  created() {
    this.role = this.$store.getters.role;
    if (this.role === 100) {
      this.getOperatorList();
    }
    this.getProjectList();
    this.getErrList();
  },

  computed: {
    // 选择运营商后 过滤项目
    filterProjectList() {
      if (this.id_operator) {
        return this.projectList.filter((i) => i.id_operator === this.id_operator);
      } else {
        return this.projectList;
      }
    },
  },

  filters: {
    deviceTypeFmt(v) {
      switch (v) {
        case 0:
          return "水箱";
        case 1:
          return "水泵";
        case 2:
          return "机组";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.anomaly_statistics {
  width: 100%;
  min-height: 100%;
  border-radius: $radius;
  background-color: #fff;
  padding: 12px;
}

.table_box {
  padding: 0 12px;
  margin-top: 12px;
}
</style>
